import axios from './ECommerce.axio'
import moment from 'moment'
const storeName = '_ :: E-Commerce ::>'
export default {
  namespaced: true,
  state: {
    id: 0,
    contribuyente: {},
    tipoConsulta: 'T',
    servicios: [],
    paquetes: [],
    restricciones: [],
    documentos: [],
    arreglosPagos: {},
    montos: {
      interes: 0,
      iva: 0,
      monto: 0,
      multa: 0,
      total: 0,
    },
    vPosData: {},
    vPosMensaje: []
  },
  getters: {
    storeId: state => state.id,
    storeContribuyente: state => state.contribuyente,
    storeTipoConsulta: state => state.tipoConsulta,
    storeServicios: state => state.servicios,
    storePaquetes: state => state.paquetes,
    storeRestricciones: state => state.restricciones,
    storeMontos: state => state.montos,
    storeDocumentos: state => state.documentos,
    storeArreglosPagos: state => state.arreglosPagos,

    storeVPosData: state => state.vPosData,
    storeVPosMensaje: state => state.vPosMensaje
  },
  mutations: {
    CAMBIAR_ID(state, payload) {
      state.id = payload
    },
    CAMBIAR_CONTRIBUYENTE(state, payload) {
      state.contribuyente = payload
    },
    CAMBIAR_TIPOCONSULTA(state, payload) {
      state.tipoConsulta = payload
    },
    CAMBIAR_SERVICIOS(state, payload) {
      state.servicios = payload
    },
    CAMBIAR_PAQUETES(state, payload) {
      state.paquetes = payload
    },
    CAMBIAR_RESTRICCIONES(state, payload) {
      state.restricciones = payload
    },
    CAMBIAR_MONTOS(state, payload) {
      state.montos = payload
    },
    CAMBIAR_DOCUMENTOS(state, payload) {
      state.documentos = payload
    },
    CAMBIAR_ARREGLOSPAGOS(state, payload) {
      state.arreglosPagos = payload
    },
    CAMBIAR_CUoTASAPAGAR(state, payload) {
      state.cuotasAPagar = payload
    },
    CAMBIAR_VPOSDATA(state, payload) {
      state.vPosData = payload
    },
    CAMBIAR_VPOSMENSAJE(state, payload) {
      state.vPosMensaje = payload
    }
  },
  actions: {
    /**
         */

    async storeDoEnviarxCorreo({ commit }, params) {
      console.log(storeName + 'storeDoEnviarxCorreo ::', params)
      try {
        const result = await axios.doSendEmail(params)
      } catch (error) {
        alert(error)
        console.log(storeName + 'error :>> ', { ...error })
      }
    },
    async storeGetServicios({ commit }) {
      console.log(storeName + 'storeGetServicios ::')
      try {
        const result = await axios.doServicios()
        commit('CAMBIAR_SERVICIOS', result.data)
      } catch (error) {
        alert(error)
        commit('CAMBIAR_SERVICIOS', [])
        console.log(storeName + 'error :>> ', { ...error })
      }
    },
    /**
         */
    async storeGetPaquetes({ commit }) {
      console.log(storeName + 'storeGetPaquetes ::')
      try {
        const result = await axios.doPaquetes()
        commit('CAMBIAR_PAQUETES', result.data)
      } catch (error) {
        alert(error)
        commit('CAMBIAR_PAQUETES', [])
        console.log(storeName + 'error :>> ', { ...error })
      }
    },
    /**
         */
    async storeGetRestricciones({ commit }) {
      console.log(storeName + 'storeGetRestricciones ::')
      try {
        const result = await axios.doRestricciones()
        commit('CAMBIAR_RESTRICCIONES', result.data)
      } catch (error) {
        alert(error)
        commit('CAMBIAR_RESTRICCIONES', [])
        console.log(storeName + 'error :>> ', { ...error })
      }
    },

    /**
         *
         * @param {*} cedula
         */
    async storeGetArregosPagos({ commit }, cedula) {
      console.log(storeName + 'storeGetArregosPagos ::', cedula)
      const tipoConsulta = "eP"
      commit('CAMBIAR_TIPOCONSULTA', tipoConsulta)
      try {
        const result = await axios.doConsulta(cedula, tipoConsulta)
        let arregosPagos = result.data
        console.log(storeName + 'storeGetArregosPagos ::', result.data)

        commit('CAMBIAR_ARREGLOSPAGOS', [])
        commit('CAMBIAR_CONTRIBUYENTE', {})

        if (arregosPagos.arregloPago) {

          for (let jIndex = 0; jIndex < arregosPagos.arregloPago.cuotas.length; jIndex++) {
            const fechasPartes = arregosPagos.arregloPago.cuotas[jIndex].fechaVencimiento.split('/')
            arregosPagos.arregloPago.cuotas[jIndex].fechaCuota = new Date(fechasPartes[2] + '/' + fechasPartes[1] + '/' + fechasPartes[1])
            arregosPagos.arregloPago.cuotas[jIndex].estado = ' '
          }

          // no viene ordenado asi que hay que ordenar
          arregosPagos.arregloPago.cuotas = arregosPagos.arregloPago.cuotas.sort((x, y) => x.fechaCuota > y.fechaCuota ? 1 : -1)
          const fechaHOY = new Date()

          for (let jIndex = 0; jIndex < arregosPagos.arregloPago.cuotas.length; jIndex++) {
            arregosPagos.arregloPago.cuotas[jIndex].id = jIndex
            arregosPagos.arregloPago.cuotas[jIndex].descripcion = 'Arreglo de pago: ' + arregosPagos.arregloPago.cuotas[jIndex].numeroFinanciamiento + ' Cuota #' + + arregosPagos.arregloPago.cuotas[jIndex].numero
            // Cero
            if (arregosPagos.arregloPago.cuotas[jIndex].numero == 0) {
              // arregosPagos.arregloPago.cuotas[jIndex].descripcion += ' - Inicial'
              arregosPagos.arregloPago.cuotas[jIndex].estado += ' - Inicial'
            }

            // console.log('fechas de areglos de pagos :>> ', arregosPagos.arregloPago.cuotas[jIndex].fechaCuota);
            // console.log('Fecha comparacion          :>> ', fechaHOY);


            const dateParts = arregosPagos.arregloPago.cuotas[jIndex].fechaVencimiento.split("/");
            const fechaVencimiento = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

            // console.log('fechaVencimiento,  fechaHOY:>> ', arregosPagos.arregloPago.cuotas[jIndex].fechaVencimiento, fechaVencimiento, fechaHOY);

            if (fechaVencimiento < fechaHOY) {
              // arregosPagos.arregloPago.cuotas[jIndex].descripcion += ' - Cuota Vencida'
              arregosPagos.arregloPago.cuotas[jIndex].estado = 'Cuota Vencida'
            } else if (fechaVencimiento.getMonth() == fechaHOY.getMonth() && fechaVencimiento.getYear() == fechaHOY.getYear()) {
              // arregosPagos.arregloPago.cuotas[jIndex].descripcion += ' - Cuota al Cobro'
              arregosPagos.arregloPago.cuotas[jIndex].estado += 'Cuota al Cobro'
            }
            commit('CAMBIAR_ARREGLOSPAGOS', arregosPagos)
          }


          commit('CAMBIAR_CONTRIBUYENTE', result.data.contribuyente)
        }
      } catch (error) {
        console.log(storeName + 'storeGetArregosPagos error :>> ', error)
        commit('CAMBIAR_CONTRIBUYENTE', {})
        commit('CAMBIAR_ARREGLOSPAGOS', [])
      }



    },


    /**
         *
         * @param {*} param0
         * @param {*} params
         */
    async storeGetDocumentos({ commit, state }, params) {
      console.log(storeName + 'storeGetDocumentos ::', params)
      commit('CAMBIAR_TIPOCONSULTA', params.tipoConsulta)
      let montos = {
        interes: 0,
        iva: 0,
        monto: 0,
        multa: 0,
        total: 0,
      }
      moment.locale('es-es')
      try {
        const result = await axios.doConsulta(params.cedula, params.tipoConsulta)
        // console.log(' storeGetDocumentos result :>> ', result.data)
        montos = {
          interes: result.data.interes,
          iva: result.data.iva,
          monto: result.data.monto,
          multa: result.data.multa,
          total: result.data.total,
        }

        const cobros = result.data.cobros
        for (let iI = 0; iI < cobros.length; iI++) {
          // console.log('cobros[iI] :>> ', iI, cobros[iI]);
          const periodo = cobros[iI].periodo
          const anoPeriodo = periodo.split('.')

          const periodo0 = anoPeriodo[0]
          const periodo1 = anoPeriodo[1]

          cobros[iI].id = iI
          cobros[iI].ano = periodo0

          const servicio = state.servicios.filter(servicio => servicio.auxiliar == cobros[iI].auxiliar && servicio.codigo == cobros[iI].codigo)
          cobros[iI].tipo = 'MENSUAL'
          if (servicio.length > 0) {
            cobros[iI].tipo = servicio[0].tipoCobro
            cobros[iI].tipo_orden = 1
            switch (servicio[0].tipoCobro) {
              case 'MENSUAL':
                const mes = periodo1 === '1' ? 'Octubre' : moment().month(periodo1 - 1).format('MMMM')
                cobros[iI].tipo = servicio[0].tipoCobro
                cobros[iI].mes = mes[0].toUpperCase() + mes.substring(1)
                cobros[iI].mes = cobros[iI].mes.charAt(0).toUpperCase() + cobros[iI].mes.slice(1)
                cobros[iI].tipo_orden = 3
                break

              case 'TRIMESTRAL':
                const finPeriodo = periodo1 * 3
                cobros[iI].periodo = periodo0 + '.' + ('00000' + finPeriodo).slice(-2)
                cobros[iI].tipo = servicio[0].tipoCobro
                cobros[iI].mes = (periodo1 === '01' ? '1er' : periodo1 === '02' ? '2do' : periodo1 === '03' ? '3er' : '4to') + ' Trimestre'
                cobros[iI].tipo_orden = 2
                break

              case 'ANUAL':
                cobros[iI].mes = 'ANUAL'
                cobros[iI].tipo_orden = 1
                break
              case 'UNICO':
                cobros[iI].mes = 'UNICO'
                cobros[iI].tipo_orden = 4
                break

              default:
                cobros[iI].mes = anoPeriodo[1] + ' ' + servicio[0].tipoCobro
                cobros[iI].tipo_orden = 3
                break
            }
          }
        }
        commit('CAMBIAR_DOCUMENTOS', cobros)
        commit('CAMBIAR_CONTRIBUYENTE', result.data.contribuyente)
      } catch (error) {
        commit('CAMBIAR_DOCUMENTOS', [])
        commit('CAMBIAR_CONTRIBUYENTE', {})
        console.log(storeName + 'storeGetDocumentos error :>> ', { ...error })
      }
      commit('CAMBIAR_MONTOS', montos)
    },
    /**
         *
         * @param {*} param0
         * @param {*} params
         * @returns Error   si existe mensaje de error en servidor
         */
    async storeSendPagos({ commit }, params) {
      console.log(storeName + 'storeSendPagos ::', params)
      try {
        const result = await axios.doSendPagos(params)
        console.log('result :>> ', result);
        if (result.data.hasOwnProperty('error') && result.data.error.length > 0) {
          commit('CAMBIAR_VPOSMENSAJE', [result.data.error])
          return false
        }
        if (result.data.vpos.PAR) {
          commit('CAMBIAR_VPOSDATA', result.data.vpos.PAR)
        } else if (result.data.vpos.TOT) {
          commit('CAMBIAR_VPOSDATA', result.data.vpos.TOT)
        } else if (result.data.vpos.ARP) {
          commit('CAMBIAR_VPOSDATA', result.data.vpos.ARP)
        } else {
          console.log('revisar objeto :>> ', result.data.vpos);
          commit('CAMBIAR_VPOSDATA', {})
          return false
        }

        console.log('storeSendPagos result:>> OK', result.data.vpos)
        return true
      } catch (error) {
        commit('CAMBIAR_VPOSDATA', {})
        console.log(storeName + 'error :>> ', { ...error })
      }
      return false
    }
  }
}
