import axios from '@/_API/AXIOS'

/**
http://172.16.24.25:8080/services/servicios
http://172.16.24.25:8080/services/restricciones
http://172.16.24.25:8080/services/paquetes
http://172.16.24.25:8080/services/consulta/203620831/p
Esos son los servicios

 */
const PREFIX_URL = '_PHP/'

export default {

  async doSendEmail(params) {
    console.log('doSendEmail :>> ', params)

    const headers = { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
    const enviarCorreoRecibo = new URLSearchParams()
    enviarCorreoRecibo.append('b64jrequest', btoa(JSON.stringify(params)))
    return await axios().post(PREFIX_URL + 'enviarrecibo.php', enviarCorreoRecibo, { headers })
  },

  async doServicios() {
    return await axios().get(PREFIX_URL + 'servicios.php')
  },
  async doPaquetes() {
    return await axios().get(PREFIX_URL + 'paquetes.php')
  },
  async doRestricciones() {
    return await axios().get(PREFIX_URL + 'restricciones.php')
  },
  async doConsulta(cedula, tipo) {
    console.log('URL Consulta :>> ', PREFIX_URL + 'consulta.php/?cedula=' + cedula + '&tipoConsulta=' + tipo);
    return await axios().get(PREFIX_URL + 'consulta.php/?cedula=' + cedula + '&tipoConsulta=' + tipo)
  },
  async doSendPagos(pagos) {
    console.log('doSendPagos cedula :>> ', pagos.cedula)
    const headers = { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }

    const pagoForm = new URLSearchParams()
    pagoForm.append('b64jrequest', btoa(JSON.stringify(pagos)))

    return await axios().post(PREFIX_URL + 'pagos.php', pagoForm, { headers })
  },
  async doStartBankPage() {

  }

}
