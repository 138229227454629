<template>
    <v-card class='mx-auto' max-width='90%' height="90%">
        <v-toolbar color='primary' dark>
            <v-toolbar-title>Terminos y Condiciones</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn light @click='onReport'>
                <v-icon class>mdi-printer</v-icon>
            </v-btn>
            <v-btn light @click='onExit'>
                <v-icon class>mdi-close-box-outline</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
            <iframe src="https://muniorotina.go.cr/index.php/ayuda/terminosycondiciones"></iframe>
        </v-card-text>
        <v-divider class='mx-4'></v-divider>
        <v-card-actions class='justify-center'>
            <v-btn light @click='onReport'>
                <v-icon class>mdi-printer</v-icon>
            </v-btn>
            <v-btn light @click='onExit'>
                <v-icon class>mdi-close-box-outline</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "TerminosyCondicionesForm",
        props: ["modalResult"],
        components: {

        },
        data: () => ({

        }),
        watch: {
        },

        async created() {
        },
        computed: {
        },
        methods: {
            onReport() {
                window.print()
            },
            onExit() {
                console.log('close terminos');
                this.$emit("modalResult", 'close');
            }
        }


    }
</script>

<style scoped>
iframe {
    overflow:hidden;
    display: block;       /* iframes are inline by default */
    background: #000;
    border: none;         /* Reset default border */
    height: 80vh;        /* Viewport-relative units */
    width: 95vw;
}
</style>