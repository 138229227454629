
<template>
    <v-app>
        <ECommerce />
    </v-app>
</template>

<script>
    import ECommerce from "@/Views/ECommerce"
    export default {
        name: 'App',
        components: {
            ECommerce
        },

        data: () => ({

        }),
        methods: {
        },
    };
</script>
<style scoped>
    #app {
    	background: transparent;
    }
</style>

