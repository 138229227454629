<template>
    <form method='post' class='alignet-form-vpos2' action='javascript:;' style='display: none'>
        <table>
            <tr>
                <td>AcquirerId</td>
                <td>
                    <input type='text' name='acquirerId' v-model='storeVPosData.acquirerId' />
                </td>
            </tr>
            <tr>
                <td>IdCommerce</td>
                <td>
                    <input type='text' name='idCommerce' v-model='storeVPosData.idCommerce' />
                </td>
            </tr>
            <tr>
                <td>PurchaseAmount</td>
                <td>
                    <input type='text' name='purchaseAmount' v-model='storeVPosData.purchaseAmount' />
                </td>
            </tr>
            <tr>
                <td>PurchaseCurrencyCode</td>
                <td>
                    <input type='text' name='purchaseCurrencyCode' v-model='storeVPosData.purchaseCurrencyCode' />
                </td>
            </tr>
            <tr>
                <td>PurchaseOperationNumber</td>
                <td>
                    <input type='text' name='purchaseOperationNumber' v-model='storeVPosData.purchaseOperationNumber' />
                </td>
            </tr>
            <tr>
                <td>Language</td>
                <td>
                    <input type='text' name='language' v-model='storeVPosData.language' />
                </td>
            </tr>
            <tr>
                <td>ShippingFirstName</td>
                <td>
                    <input type='text' name='shippingFirstName' v-model='storeVPosData.shippingFirstName' />
                </td>
            </tr>
            <tr>
                <td>ShippingLastName</td>
                <td>
                    <input type='text' name='shippingLastName' v-model='storeVPosData.shippingLastName' />
                </td>
            </tr>
            <tr>
                <td>ShippingEmail</td>
                <td>
                    <input type='text' name='shippingEmail' v-model='storeVPosData.shippingEmail' />
                </td>
            </tr>
            <tr>
                <td>ShippingAddress</td>
                <td>
                    <input type='text' name='shippingAddress' v-model='storeVPosData.shippingAddress' />
                </td>
            </tr>
            <tr>
                <td>ShippingZIP</td>
                <td>
                    <input type='text' name='shippingZIP' v-model='storeVPosData.shippingZIP' />
                </td>
            </tr>
            <tr>
                <td>ShippingCity</td>
                <td>
                    <input type='text' name='shippingCity' v-model='storeVPosData.shippingCity' />
                </td>
            </tr>
            <tr>
                <td>ShippingState</td>
                <td>
                    <input type='text' name='shippingState' v-model='storeVPosData.shippingState' />
                </td>
            </tr>
            <tr>
                <td>ShippingCountry</td>
                <td>
                    <input type='text' name='shippingCountry' v-model='storeVPosData.shippingCountry' />
                </td>
            </tr>
            <tr>
                <td>UserCommerce</td>
                <td>
                    <input type='text' name='userCommerce' v-model='storeVPosData.userCommerce' />
                </td>
            </tr>
            <tr>
                <td>UserCodePayme</td>
                <td>
                    <input type='text' name='userCodePayme' v-model='storeVPosData.userCodePayme' />
                </td>
            </tr>
            <tr>
                <td>DescriptionProducts</td>
                <td>
                    <input type='text' name='descriptionProducts' v-model='storeVPosData.descriptionProducts' />
                </td>
            </tr>
            <tr>
                <td>ProgrammingLanguage</td>
                <td>
                    <input type='text' name='programmingLanguage' v-model='storeVPosData.programmingLanguage' />
                </td>
            </tr>
            <tr>
                <td>PurchaseVerification</td>
                <td>
                    <input type='text' name='purchaseVerification' v-model='storeVPosData.purchaseVerification' />
                </td>
            </tr>
            <tr>
                <td>Reserved1</td>
                <td>
                    <input type='text' name='reserved1' v-model='storeVPosData.reserved1' />
                </td>
            </tr>
        </table>
    </form>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "ECommerceBankForm",
    components: {},

    data: () => ({}),
    computed: {
        ...mapGetters("ECommerce", ["storeVPosData"]),
    },
    mounted() {
        console.log('Datos de Banco Macional :>> ', JSON.stringify(this.storeVPosData));
        // setTimeout(function () { AlignetVPOS2.openModal('', '1'); }, 300);
        // const URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_INTEGRACION_PRODUCCION : process.env.VUE_APP_INTEGRACION_PRUEBA

        const URL = process.env.NODE_ENV === 'production' ? 'https://vpayment.verifika.com/' : 'https://integracion.alignetsac.com/'

        console.log('URL :>> ', `AlignetVPOS2.openModal('${URL}'`)
        AlignetVPOS2.openModal(URL)
    },
    methods: {
    },
};
</script>
<style scoped>

</style>