<template >
  <v-container class="pss_container" max-width="400px" style="overflow: auto; max-height: 85vh">

    <v-dialog v-model="flsEspere" hide-overlay persistent width="250px">
      <v-card color="#26526b" dark>
        <v-card-text style="padding-top: 10px;">
          Enviando Correo...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-card class="mx-auto" max-width="400px" style="box-shadow: none" color="#EEEEEE">
      <v-toolbar color="#26526b" dark align-text="center">
        <v-toolbar-title>Comprobante</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="onExit" fab dark small color="red" style="
                                                    text-decoration: none;
                                                    min-width: 12px;
                                                    width: 9vw;
                                                    height: 9vw;
                                                    max-height: 40px;
                                                    max-width: 40px;
                                                    margin-left: 1px !important;
                                                    margin-right: 1px !important;
                                                  " class="mx-2">
          <v-icon style="font-size: max(1.5vw, 1.2em)" dark>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row dense>
          <v-col lg="12" md="12" sm="12" xs="12" style="text-align: center;">
            <v-text-field style="display: inline-block;min-width: 280px;max-width: 350px;height: 68px;width: 100%;" outlined type="email" v-model="emailEnviar" label="Digite su Correo Electronico" :rules="emailRules" clear-icon="mdi-close-outline" prepend-inner-icon="mdi-email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="12" md="12" sm="12" xs="12" style="text-align: center;">
            <div style="text-align: center">
              <v-btn style="display: inline-block" class="text-center" :disabled="!flsEmailCorrecto" color="primary" @click="onEnviarCorreoElectronico" height="6vh">
                <v-icon class>mdi-email</v-icon>Enviar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="d-none d-lg-block">
        <v-divider class="mx-4"></v-divider>
        <v-card-actions class="justify-center">
          <v-btn class="botones" color="primary" @click="onSalvarDocumento">
            <v-icon class></v-icon>Guardar
          </v-btn>
          <v-btn class="botones" color="primary" @click="onVistaPrevioDocumento">
            <v-icon class></v-icon>Visualizar
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ReciboPagoForm",
  props: ["modalResult", "comprobante"],
  components: {},
  data: () => ({
    emailEnviar: "",
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Correo Electronico no tiene formato correcto",
    ],

    flsEmailCorrecto: false,
    flsEspere: false,
  }),
  watch: {
    emailEnviar: {
      handler: function (after, before) {
        this.flsEmailCorrecto = after.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      },
    },
  },

  async created() { },
  computed: {},
  mounted() {
    this.flsEmailCorrecto = false; // Desabilita boton de Enviar Correo
  },
  methods: {
    ...mapActions('ECommerce', ['storeDoEnviarxCorreo']),
    async onEnviarCorreoElectronico() {
      /**
       *
       *  {    "recibo":"20230213_093153-4683__8200173.pdf",
       *       "reserved":"ycmuWege3Re5RB85XkXxHw\u003d\u003d",
       *       "cedula":"888888888",
       *       "error":false,
       *       "message":"El pago se ha realizado con exito"     }
       *
       *
       */
      // {        "service:" "ecommerce-email-recibo", "cedula": "", "reserved": "", "recibo": "", '"email": "" }

      const paramsEnviarCorreo = {
        service: "ecommerce-email-recibo",
        cedula: this.comprobante.cedula,
        recibo: this.comprobante.recibo,
        reserved: this.comprobante.reserved,
        email: this.emailEnviar,
      };
      this.flsEspere = true;
      try {
        await this.storeDoEnviarxCorreo(paramsEnviarCorreo);
        alert("Correo fue enviado a " + this.emailEnviar);
      } catch (error) {
        alert("Error en Enviar Comprobante x Correo");
        console.log("Error en  Enviar Comprobante x Correo   ", error);
      }
      this.flsEspere = false;
    },
    onSalvarDocumento() {
      const linkSource = '/_PHP/recibo.php?pdf=' + this.comprobante.recibo;
      window.open(linkSource)
    },
    onVistaPrevioDocumento() {
      const documentoPDF = '/_PHP/recibo.php?pdf=' + this.comprobante.recibo
      window.open(documentoPDF)
    },
    onExit() {
      console.log("close terminos");
      this.$emit("modalResult", "close");
    },
  },
};
</script>

<style scoped>
.botones {
  width: 8rem;
}

.scrollbox_row {
  overflow-y: auto;
}

.tipoDocumento {
  text-align: center;
  font-size: 1em;
  color: black;
}

/deep/ .v-input--radio-group__input {
  text-align: center;
  font-size: 2em;
  padding: 0 1.2em;
}

/deep/ .v-input--radio-group__input>.v-label {
  font-size: 0.82em;
  font-weight: bold;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#appwrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#appwrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
div.app-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
div.app-wrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for IE, Edge and Firefox */
/deep/ #appwrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
/deep/ #appwrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/deep/ div.app-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
/deep/ div.app-wrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>