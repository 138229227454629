<template>
    <v-container class="my-n3" style="overflow: auto; max-height: 85vh;">
        <div v-if="flsOnCargarDocumento" style="margin: 3rem">
            <v-row justify="center">
                <v-progress-circular :size="100" :width="7" color="info" indeterminate></v-progress-circular>
            </v-row>
        </div>

        <v-dialog class="pss_cobros" v-model="flsRecibo" hide-overlay transition="dialog-bottom-transition">
            <EComerceRecibos :comprobante="comprobante" @modalResult="closeRecibo" />
        </v-dialog>

        <v-dialog class="pss_cobros" v-model="flsListaPagos" fullscreen hide-overlay transition="dialog-bottom-transition">
            <ECommercePagos :cedula="cedula" @modalResult="closePagos" />
        </v-dialog>

        <v-dialog class="pss_cobros" v-model="flsArregloPago" fullscreen hide-overlay transition="dialog-bottom-transition">
            <ECommerceArreglo @modalResult="closeArreglo" />
        </v-dialog>

        <v-dialog class="pss_terminos" v-model="flsShowTerminos" fullscreen hide-overlay transition="dialog-bottom-transition">
            <TerminosyCondiciones @modalResult="closeTerminos" />
        </v-dialog>

        <v-card class="mx-auto" width="400px" max-width="100%" v-if="showMainForm()">
            <v-toolbar color="#26526b" dark align-text="center">
                <v-row class style="padding-top: 0px; padding-left: 0px; padding-right: 0px">
                    <v-col lg="6" md="6" sm="6" xs="6">
                        <v-toolbar-title>Pago en Linea</v-toolbar-title>
                    </v-col>
                    <v-col lg="6" md="6" sm="6" xs="6" style="text-align: right">
                        <v-btn href="https://muniorotina.go.cr/index.php/component/sppagebuilder/?view=page&id=526" target="_blank" class="mx-2" fab dark small color="#14BF98" style="text-decoration: none">
                            <v-icon dark>mdi-help</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>

            <v-dialog class="pss_mensaje" v-model="flsMensajeECommerce" max-width="400px" width="100%">
                <v-toolbar height="35px" color="#26526b" dark align-text="center">
                    <v-toolbar-title style="font-size: max(1vw, 1em)">Mensaje del Sistema</v-toolbar-title>
                </v-toolbar>
                <v-card class="mx-auto mx-2" width="100%" max-width="400px" color="#EEEEEE">
                    <v-card-text style="padding-top: 10px;  padding-bottom: 0px;" color="#EEEEEE" justify="left">
                        <ul style="padding-bottom: 10px;margin-bottom: 0px;" v-for="(mensaje, index) in mensajeECommerce" v-bind:key="index">
                            <p class="font-weight-medium roboto" style="margin-bottom: 0px; padding-left: 1px; padding-botton: 1px; font-size: max(0.8vw, 0.9em); font-family: Roboto,sans-serif!important; font-weight: 500;">{{ mensaje }}</p>
                        </ul>
                    </v-card-text>

                    <v-card-actions>
                        <v-row lg="12" md="12" sm="12" xs="12" style="text-align: center; margin-bottom: 0px
                                                                                ">
                            <v-col lg="12" md="12" sm="12" xs="12" class="col-12">
                                <v-btn dark small color="info" style="padding-left: 8px;
                                                                                        padding-right: 8px;
                                                                                        font-size: max(0.06vw, 0.7em);
                                                                                      text-decoration: none" class="ma-0 text-center" v-if="urlRecibo > ''" :href="urlRecibo" download>
                                    <v-icon>mdi-cloud-download</v-icon>Descargar Comprobante
                                </v-btn>
                            </v-col>
                            <v-col lg="12" md="12" sm="12" xs="12" class="col-12">
                                <v-btn dark small color="#14BF98" style="padding-left: 8px;
                                                                                        padding-right: 8px;
                                                                                        font-size: max(0.06vw, 0.7em);
                                                                                      text-decoration: none" class="ma-0 text-center" @click="flsMensajeECommerce = false">
                                    <v-icon>mdi-window-close</v-icon>Cerrar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-container style="padding-botton: 4px">
                <v-row class="justify-center align-center" style="padding-top: 8px">
                    <v-img class lazy-src="images/MuniPagos.png" max-height="124" max-width="212" src="images/MuniPagos.png"></v-img>
                </v-row>
            </v-container>

            <v-container style="padding-top: 1px">
                <v-row class="justify-center align-center" style="padding-top:0px; padding-left:5%; padding-right:5% height: 64px;">
                    <v-col lg="10" md="10" sm="10" xs="10">
                        <v-text-field v-model="cedula" label="Digite su Cédula" prepend-inner-icon="mdi-account-box-outline"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <v-row class="justify-center align-center" style="
                                                                              padding-left: 2%;
                                                                              padding-right: 2%;
                                                                              padding-top: 0px;
                                                                              padding-bottom: 0px;
                                                                            ">
                <v-radio-group row class="ma-0 pa-0 justify-center align-center" v-model="tipoConsulta" outlined style="padding-left: 5%; padding-right: 5%">
                    <v-radio label="Parcial" value="p"></v-radio>
                    <v-radio label="Total" value="t"></v-radio>
                    <v-radio label="Arreglo" value="eP"></v-radio>
                </v-radio-group>
            </v-row>

            <v-row class="justify-center align-center" style="
                                                                              padding-left: 2%;
                                                                              padding-right: 2%;
                                                                              padding-top: 0px;
                                                                              padding-bottom: 0px;
                                                                              margin-top: 0px;
                                                                            ">
                <v-col style="padding-top: 0px; padding-bottom: 12px; margin-top: 0px">
                    <p class="rounded-xl blue lighten-5 text-center ;" style="
                                                                                  font-size: max(0.06vw, 0.8em);
                                                                                  line-height: 20px;
                                                                                  margin-bottom: 0px;
                                                                                ">{{ tipoConsulta === "p" ? textoAyudaPagoPacial : tipoConsulta === "t" ? textoAyudaPagoTotal : textoAyudaArregloPago }}</p>
                </v-col>
            </v-row>

            <v-row class="justify-center align-center" style="
                                                                              padding-top: 0px;
                                                                              padding-bottom: 0px;
                                                                              margin-top: 0px;
                                                                              padding-left: 12%;
                                                                              padding-right: 12%;
                                                                              height: 75px;
                                                                            ">
                <v-col lg="12" md="12" sm="12" xs="12">
                    <v-switch class="text-center" style="margin-top: 0px" v-model="flsAceptaTerminos" inset label="Acepta Terminos y Condiciones"></v-switch>
                </v-col>
            </v-row>

            <v-divider style="
                                                                              margin-top: 0px;
                                                                              padding-left: 5%;
                                                                              padding-right: 5%;
                                                                              height: 75px;
                                                                            " class="mx-4"></v-divider>

            <v-card-actions class="pss_buttons">
                <v-row class="justify-center align-center" style="margin-top: 0px; padding-left: 1%; padding-right: 1%">
                    <v-col style="padding-top: 0px" class="text-center" lg="6" md="6" sm="6" xs="6">
                        <v-btn class="ma-1 text-center" style="font-size: max(0.06vw, 0.7em)" @click="flsShowTerminos = true">
                            Terminos y
                            <br />Condiciones
                        </v-btn>
                    </v-col>
                    <v-col style="padding-top: 0px" class="text-center" lg="6" md="6" sm="6" xs="6">
                        <v-btn style="font-size: max(0.06vw, 0.9em)" class="ma-1 text-center" @click="onCargarDocumentos" :disabled="!flsAceptaTerminos" color="info">
                            <v-icon></v-icon>Consultar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import TerminosyCondiciones from './TerminosyCondiciones'
import ECommercePagos from './ECommerce.pagos'
import ECommerceArreglo from './ECommerce.arreglo.vue'
import EComerceRecibos from './ECommerce.recibo.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ECommerceForm',
    components: {
        TerminosyCondiciones,
        ECommercePagos,
        ECommerceArreglo,
        EComerceRecibos
    },

    data: () => ({
        mainTitle: process.env.VUE_APP_TITLE,
        id: 1,
        cedula: '',
        tipoConsulta: 'p',
        nombre: '',
        urlRecibo: '',

        flsRecibo: false,
        comprobante: {},

        flsAceptaTerminos: false,
        flsShowTerminos: false,
        flsListaPagos: false,
        flsArregloPago: false,
        flsMensajeECommerce: false,
        mensajeECommerce: [],
        colorMensajeInicial: 'success',
        flsOnCargarDocumento: false,
        toggle_exclusive: 0,
        // ------- Mensajes de Ayuda
        textoAyudaPagoPacial: '',
        textoAyudaPagoTotal: '',
        textoAyudaArregloPago: '',
        //
    }),
    watch: {},
    async created() {
        this.initialize()
        this.textoAyudaPagoPacial = 'Consulta de todos los tributos vencidos y los tributos que se encuentran al cobro a la fecha de realización del pago.'
        this.textoAyudaPagoTotal = 'Consulta de los tributos vencidos, puestos al cobro y los no vencidos hasta el 31 de diciembre del año en curso.'
        this.textoAyudaArregloPago = 'Consulta de las cuotas vencida y puesta al cobro del Arreglo de Pago Municipal'
    },
    async mounted() {
        let parametrosPagos = {}
        const urlParams = window.location.search.substring(1)
        console.log('urlParams :>> ', urlParams)
        if (urlParams.length > 0) {
            try {
                /**
                             * {"status":201 , "message":"Operacion Rechazada"}
                             */
                parametrosPagos = JSON.parse(atob(urlParams.split('=')[0]))
            } catch (error) {
                parametrosPagos = {
                    error: false,
                    message: 'No puedo Procesar Mensaje Inicial y/o Mensaje Inicial Mal Formado'
                }
            }
            console.log('parametrosPagos :>> ', parametrosPagos)
            /**
             *
             *  {    "recibo":"20230213_093153-4683__8200173.pdf",
             *       "reserved":"ycmuWege3Re5RB85XkXxHw\u003d\u003d",
             *       "cedula":"888888888",
             *       "error":false,
             *       "message":"El pago se ha realizado con exito"     }
             *
             *
             */


            this.colorMensajeInicial = parametrosPagos.error ? 'error' : 'success'
            this.mensajeECommerce = [parametrosPagos.message.replace('exito', 'éxito').replace('Operacion', 'Operación')]
            this.urlRecibo = ''
            // if (parametrosPagos.hasOwnProperty('recibo')) {
            //     this.urlRecibo = '/_PHP/recibo.php?pdf=' + parametrosPagos.recibo
            //     window.open(this.urlRecibo)
            // }
            // this.flsMensajeECommerce = true

            console.log('parametrosPagos.error == true :>> ', parametrosPagos.error == true, parametrosPagos.error);

            if (parametrosPagos.error == true) {
                this.flsMensajeECommerce = true
            }
            else {
                this.comprobante = parametrosPagos
                this.flsRecibo = true

            }
        }
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'prueba') {
            this.cedula = "888888888"
            console.log('process.env :>> ', process.env);
        }
    },

    computed: {
        ...mapGetters('ECommerce', ['storeTipoConsulta', 'storeServicios', 'storePaquetes', 'storeRestricciones']),
        ...mapGetters('ECommerce', ['storeContribuyente', 'storeDocumentos', 'storeArreglosPagos']),
    },
    methods: {
        ...mapActions('ECommerce', ['storeGetServicios', 'storeGetPaquetes', 'storeGetRestricciones']),
        ...mapActions('ECommerce', ['storeGetDocumentos', 'storeGetArregosPagos']),
        currencyFormat(numero) {
            return numero.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },

        async onReport() {
            window.print()
        },
        async onExit() {
            console.log('process.env.VUE_APP_EXIT_URL :>> ', process.env.VUE_APP_EXIT_URL)
            window.location = process.env.VUE_APP_EXIT_URL
        },
        async initialize() {
            await this.storeGetServicios()
            await this.storeGetPaquetes()
            await this.storeGetRestricciones()
        },
        /**
                 * Envia Informacion al BACK-END para procesar Obtener Informacion
                 *      Valida :    Si Existe Cedula ---> Si no existe no ecxiste propieadad de nombre
                 *                  Si tiene condicion Especial   ->  Como Cobro Judicial
                 *                  Si tiene recibos Pendientes
                 */
        async onCargarDocumentos() {
            this.flsOnCargarDocumento = true
            this.flsAceptaTerminos = false

            if (this.tipoConsulta == "eP") {
                await this.storeGetArregosPagos(this.cedula.replace(/\s+/g, '').trim())

            } else {
                await this.storeGetDocumentos({ cedula: this.cedula.replace(/\s+/g, '').trim(), tipoConsulta: this.tipoConsulta })
            }

            console.log('this.storeContribuyente.nombre    ', this.storeContribuyente.nombre);



            if (!this.storeContribuyente.nombre || this.storeContribuyente.nombre == 'empty') {
                if (!this.storeContribuyente.nombre ) {
                    if (this.cedula=='') {
                // Contribuyente no existe
                    this.mensajeECommerce = [
                       'Por favor digite la cedula.',
                       'Formato de la cedula Ej. 222222222.'

                    ]
                    this.colorMensajeInicial = 'error'
                    this.flsMensajeECommerce = true
                    this.cedula = ''
                    }else
                    {
                        this.mensajeECommerce = [
                        'Nuestro sistema está actualmente en mantenimiento programado. Por favor, inténtelo más tarde.',
                        'Gracias por su comprensión.'

                    ]
                    this.colorMensajeInicial = 'error'
                    this.flsMensajeECommerce = true
                    this.cedula = ''
                    }
                } else if (this.storeContribuyente.nombre == 'empty') {
                    // Contribuyente esta mal digitado
                    this.mensajeECommerce = [
                        'Cedula: ' + this.cedula,
                        'No existe, Por favor verifique el formato de la cedula Ej. 222222222.'
                    ]
                    this.colorMensajeInicial = 'error'
                    this.flsMensajeECommerce = true
                    this.cedula = ''
                }
            } else {
                if (this.storeContribuyente.condicionContribuyente) {
                    if (this.storeContribuyente.condicionContribuyente.indexOf('<html>') >= 0 || this.storeContribuyente.condicionContribuyente == '{"error":null}' || this.storeContribuyente.condicionContribuyente == '54@Contribuyente No Tiene Pendiente ([])') {
                        this.mensajeECommerce = [
                            'Cedula: ' + this.cedula,
                            this.storeContribuyente.nombre,
                            'La persona consultada no tiene pendientes.'
                        ]
                        this.colorMensajeInicial = 'info'
                        this.flsMensajeECommerce = true
                        this.cedula = ''
                    } else {
                        if (this.storeContribuyente.condicionContribuyente == 'cj') {
                            this.mensajeECommerce = [
                                'Cedula: ' + this.storeContribuyente.cedula,
                                'Nombre: ' + this.storeContribuyente.nombre,
                                // this.storeContribuyente.condicionContribuyente,
                                '',
                                'No puede realizar el Pago en Línea porque la cuenta se encuentra en cobro judicial. Favor contactar a Gestión de Cobros al 2428-80-47 ext 120, 121, 122 o al correo cobros@muniorotina.go.cr'
                            ]
                            this.colorMensajeInicial = 'error'
                            this.flsMensajeECommerce = true
                            this.cedula = ''
                        }
                    }
                } else {
                    if (this.tipoConsulta == 'eP') {
                        if (this.storeArreglosPagos.length < 1) {
                            // Contribuyente No tiene Pendiente
                            this.mensajeECommerce = [
                                'Cedula: ' + this.cedula,
                                'Este contribuyente no posee arreglo de pago'
                            ]
                            this.colorMensajeInicial = 'info'
                            this.flsMensajeECommerce = true
                            this.cedula = ''

                        } else {
                            this.flsArregloPago = true
                        }
                    } else {
                        if (this.storeDocumentos.length < 1) {
                            // Contribuyente No tiene Pendiente
                            this.mensajeECommerce = [
                                'Cedula: ' + this.cedula,
                                this.storeContribuyente.nombre,
                                'La persona consultada no tiene pendientes.'
                            ]
                            this.colorMensajeInicial = 'info'
                            this.flsMensajeECommerce = true
                            this.cedula = ''
                        } else {
                            this.flsListaPagos = true
                        }
                    }
                }
            }
            this.flsAceptaTerminos = true
            this.flsOnCargarDocumento = false
        },
        /**
                 * Modal Result de Terminos y Condiciones.
                 * Solo Cierra Dialogo
                 */
        closeTerminos(accion) {
            console.log('accion :>> ', accion)
            this.flsShowTerminos = false
        },
        /**
                 * Modalresult.
                 * Solo cierra Dialogo
                 */
        closePagos() {
            this.flsListaPagos = false
        },
        closeArreglo() {
            this.flsArregloPago = false
        },
        showMainForm() {
            //
            if (this.flsRecibo) {
                return false
            } else

                if (this.flsOnCargarDocumento) {
                    return false
                } else if (this.flsListaPagos || this.flsArregloPago) {
                    return false
                }
            return true
        },
        closeRecibo() {
            this.flsRecibo = false
        }
    }
}
</script>
<style scoped>
/deep/ .v-dialog {
    box-shadow: none;
}

.scrollbox_row {
    overflow-y: auto;
}

.TipoConsulta {
    text-align: center;
    font-size: 1em;
    color: black;
}

/deep/ .v-input--radio-group__input {
    text-align: center;
    font-size: 2em;
    padding: 0 1.2em;
}

/deep/ .v-input--radio-group__input>.v-label {
    font-size: 0.82em;
    font-weight: bold;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#appwrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#appwrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
div.app-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
div.app-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for IE, Edge and Firefox */
/deep/ #appwrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
/deep/ #appwrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/deep/ div.app-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
/deep/ div.app-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
</style>
