<template>
    <v-card justify="center" style="position: absolute; top: 5px; right: 5px; z-index: 100">
        <ECommerceBank v-if="flsBank" />

        <v-dialog id="aceptadoDialog" v-model="flsMensajeRevision" max-width="400px" width="100%">
            <v-toolbar height="35px" color="#26526b" dark align-text="center">
                <v-toolbar-title style="font-size: max(1vw, 1em)">Mensaje del Sistema</v-toolbar-title>
            </v-toolbar>
            <v-card class="mx-auto mx-2" width="100%" max-width="400px" color="#EEEEEE">
                <!-- <v-card-title>Mensaje de Sistema: Revision Final</v-card-title> -->
                <v-card-text style="padding-top: 10px;  padding-bottom: 0px;" color="#EEEEEE" justify="left">
                    <p>Pago Cancelado por Falta de Documento(s) Relacionado(s).</p>
                    <p>Falta {{ mensajeRevision.length }} Documento(s)</p>
                    <ul v-for="(mensaje, index) in mensajeRevision" v-bind:key="index">
                        <li>{{ mensaje }}</li>
                    </ul>
                </v-card-text>

                <v-card-actions>
                    <v-row lg="12" md="12" sm="12" xs="12" style="text-align: center; margin-bottom: 0px            ">
                        <v-col lg="12" md="12" sm="12" xs="12" class="col-12">
                            <v-btn dark small color="#14BF98" style="padding-left: 8px;
                    padding-right: 8px;
                    font-size: max(0.06vw, 0.7em);
                  text-decoration: none" class="ma-0 text-center" @click="flsMensajeRevision = false">Cerrar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="400px" width="100%" id="rechazadoDialog" v-model="flsMensajePago">
            <v-toolbar height="35px" color="#26526b" dark align-text="center">
                <v-toolbar-title style="font-size: max(1vw, 1em)">Mensaje del Sistema</v-toolbar-title>
            </v-toolbar>
            <v-card class="mx-auto mx-2" width="100%" max-width="400px" color="#EEEEEE">
                <v-card-title></v-card-title>
                <v-card-text>
                    <p class="font-weight-medium roboto" style="margin-bottom: 0px; padding-left: 1px; padding-botton: 1px; font-size: max(0.8vw, 0.9em); font-family: Roboto,sans-serif!important; font-weight: 500;">No es posible realizar el pago en este momento.</p>
                    <br />
                    <ul v-for="(mensaje, index) in VPosMensajes" v-bind:key="index">
                        <li class="font-weight-medium roboto" style="margin-bottom: 0px; padding-left: 1px; padding-botton: 1px; font-size: max(0.8vw, 0.9em); font-family: Roboto,sans-serif!important; font-weight: 400;">{{ mensaje }}</li>
                    </ul>
                </v-card-text>

                <v-card-actions>
                    <v-row lg="12" md="12" sm="12" xs="12" style="text-align: center; margin-bottom: 0px
            ">
                        <v-col lg="12" md="12" sm="12" xs="12" class="col-12">
                            <v-btn dark small color="#14BF98" style="padding-left: 8px;
                    padding-right: 8px;
                    font-size: max(0.06vw, 0.7em);
                  text-decoration: none" class="ma-0 text-center" text @click="flsMensajePago = false">
                                <v-icon>mdi-window-close</v-icon>Cerrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div style="position: absolute; top: 5px; right: 5px; z-index: 100">
            <v-btn href="https://muniorotina.go.cr/index.php/component/sppagebuilder/?view=page&id=526" target="_blank" fab dark small color="#14BF98" style="text-decoration: none; min-width: 12px;
          width: 9vw;
          height: 9vw;
          max-height: 40px;
          max-width: 40px; margin-left: 1px !important; margin-right: 2vh !important;" class="mx-2">
                <v-icon style="font-size: max(1.5vw, 1.2em);" dark>mdi-help</v-icon>
            </v-btn>
            <v-btn href="/" fab dark small color="red" style="text-decoration: none; min-width: 12px;
          width: 9vw;
          height: 9vw;
          max-height: 40px;
          max-width: 40px; margin-left: 1px !important; margin-right: 1px !important;" class="mx-2">
                <v-icon style="font-size: max(1.5vw, 1.2em);" dark>mdi-close</v-icon>
            </v-btn>
            <!--  <v-btn
        style="
          min-width: 12px;
          width: 8vw;
          height: 8vw;
          max-height: 32px;
          max-width: 50px;
        "
        class="ma-0"
        color="red"
        dark
        @click="onExit"
      >
        <v-icon dark> mdi-close </v-icon>
            </v-btn>-->
        </div>

        <div class="input-group input-group-sm mb-0" style="background: #26526b; color: #ffffff; padding-top: 8px; padding-bottom: 12px;">
            <div class="well well-sm col-10 col-sm-10 col-md-5 col-lg-6 col-xl-8 no-gutters" style="padding-bottom: 0px; padding-top: 0px">
                <p class style="font-size: max(1.3vw, 0.5em); color: #ffffff;line-height: 8px; margin-bottom: 0px;">
                    <v-icon style="color: #ffffff; font-size: max(1vw, 1.8em)" large left>mdi-account-circle</v-icon>
                    Cedula: {{ storeContribuyente.cedula }}
                    {{ storeContribuyente.nombre }}
                    <br />
                    <span style="font-size: max(0.7vw, 8px); color: #ffffff; padding-top: 0px; line-height: 8px">
                        Seleccione solo las líneas que desea pagar ó marque varias líneas a
                        la vez eligiendo una opción del menú desplegable (los cobros
                        relacionados se seleccionarán automáticamente)
                    </span>
                </p>
            </div>
            <div class="col-12 col-sm-10 col-md-5 col-md-5 col-lg-5 col-xl-3 no-gutters" style="padding-top: 5px; padding-bottom: 0px">
                <!-- <p
          class="mb-0"
          style="font-size: max(0.8vw, 8px); color: #ffffff; padding-top: 12px; padding-bottom:4px; line-height: 10px"
        >
          <!Seleccione automáticamente las líneas relacionadas a:
                </p>-->
                <v-select class style="font-size: max(0.9vw, 14px); padding-top: 0px; height: 40px" v-model="paqueteSeleccionado" :items="paquetesDescripciones()" @input="onSeleccionarPaquete" hide-selected label="Seleccione un paquete:" dense solo></v-select>
            </div>
        </div>
        <div>
            <v-row justify="center" dense class="mx-0">
                <v-col style="padding: 0px; cursor:pointer;" lg="12" md="12" sm="12" xs="12">
                    <v-data-table style="height: 100%" item-key="id" class="elevation-1 pss_table" v-model="recibosSeleccionados" dense multi-sort fixed-header height="65vh" disable-pagination hide-default-footer mobile-breakpoint="0" @click:row="selectRow" :headers="encabezados" :items="storeDocumentos" :sort-desc="[false, false, false, false, false]" :sort-by="['ano', 'tipo_orden', 'periodo', 'referencia']">
                        <template v-slot:item.monto="{ item }">
                            <span>{{ currencyFormat(item.monto) }}</span>
                        </template>
                        <template v-slot:item.iva="{ item }">
                            <span>{{ currencyFormat(item.iva) }}</span>
                        </template>
                        <template v-slot:item.interes="{ item }">
                            <span>{{ currencyFormat(item.interes) }}</span>
                        </template>
                        <template v-slot:item.total="{ item }">
                            <span>{{ currencyFormat(item.total) }}</span>
                        </template>
                        <template v-slot:item.referencia="{ item }">
                            <span>{{ item.referencia == 0 ? "-" : item.referencia }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <div style="text-align: center; padding-top: 1.5vh">
                <!-- Desabilita el Boton si Opcion de Banco esta activo -->
                <v-btn style="font-size: max(1.5vw, 14px)" light @click="onPagarFactura" color="info" :disabled="flsDisableBTNPagarFactura">
                    Pagar : {{ currencyFormat(montoTotales[3]) }}
                    <v-icon style="margin: 0 0 0 0.46em">mdi-credit-card-multiple</v-icon>
                </v-btn>
            </div>
        </div>
    </v-card>
</template>

<script>
import ECommerceBank from './Ecommerce.bank.bncr.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'ECommercePagosForm',
    props: ['cedula', 'modalResult'],
    components: {
        ECommerceBank
    },

    data: () => ({
        mainTitle: process.env.VUE_APP_TITLE,
        id: 1,
        loopCount: 2, // Contador de Ciclo para verificar restricciones
        tipoConsulta: 'p',
        nombre: '',
        flsTerminos: false,
        flsListaPagos: false,
        flsMensajeRevision: false,
        recibosSeleccionados: [],
        paqueteSeleccionado: 'Todos',
        flsBank: false,
        flsMensajeRevision: false,
        mensajeRevision: [],
        flsMensajePago: false,
        VPosMensajes: [],
        flsDisableBTNPagarFactura: true,
        // intervalHandle: null,
        // timeOutCount: 0,
        windowSize: { x: 0, y: 0 },
        encabezados: [{ text: 'Año', value: 'ano', sortable: false },
        { text: 'Período', value: 'mes', sortable: false },
        { text: 'Descripción', value: 'descripcion', sortable: false },
        { text: 'Monto', align: 'right', value: 'monto', sortable: false },
        { text: 'Interes', align: 'right', value: 'interes', sortable: false },
        { text: 'IVA', align: 'right', value: 'iva', sortable: false },
        { text: 'Total', align: 'right', value: 'total', sortable: false }
            // { text: "Tipo", value: "tipo", sortable: false },
            // { text: "Referencia", value: "referencia", sortable: false },
            // { text: "_Tipo", value: "tipo_orden", sortable: false },
            // { text: "_periodo", value: "periodo", sortable: false },
            // { text: "Aux", value: "auxiliar", sortable: false },
            // { text: "Cod", value: "codigo", sortable: false },
        ],
        montoTotales: [0, 0, 0, 0]
    }),
    watch: {
        recibosSeleccionados: {
            handler: function (after, before) {
                if (this.flsBank) {
                    this.flsDisableBTNPagarFactura = true
                } else {
                    this.montoTotales = [0, 0, 0, 0]
                    this.recibosSeleccionados.forEach(element => {
                        this.montoTotales[0] += element.monto
                        this.montoTotales[1] += element.interes
                        this.montoTotales[2] += element.iva
                        this.montoTotales[3] += element.total
                    })
                    this.flsDisableBTNPagarFactura = !(this.montoTotales[3] > 0)
                }
            }
        },
        cedula: {
            handler: function (after, before) {
                this.recibosSeleccionados = []
                this.paqueteSeleccionado = 'Todos'
                // this.timeOutCount = 0
            }
        }
    },
    async created() {
        this.initialize()
    },
    async mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize, {
            passive: true
        })
        this.initialize()
    },
    beforeDestroy() {
        if (typeof window === 'undefined') {
            return
        }
        window.removeEventListener('resize', this.onResize, { passive: true })
    },

    computed: {
        ...mapGetters('ECommerce', ['storeContribuyente', 'storeTipoConsulta', 'storeServicios', 'storePaquetes', 'storeRestricciones', 'storeDocumentos', 'storeVPosData', 'storeVPosMensaje']),

        tableHeight() {
            console.log('window.innerHeight  :>> ', this.windowSize)
            return this.windowSize.y - 240
        }
    },
    methods: {
        ...mapActions('ECommerce', ['storeGetServicios', 'storeGetPaquetes', 'storeGetRestricciones', 'storeGetDocumentos', 'storeSendPagos']),
        currencyFormat(numero) {
            return numero.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
        },
        selectRow(item, row) {
            // console.log("selectRow item :>> ", item);

            // Para verificar si recibo esta seleccionado
            const reciboActual = this.recibosSeleccionados.filter(
                recibo => recibo.id == item.id
            )
            if (reciboActual.length > 0) {
                row.select(false)
                this.doEliminarSeleccion(item)
            } else {
                row.select(true)
                this.doSeleccionarITEM(item)
            }
        },
        /**
             * Revision de Si esta cancelando Recibo Viejo
             * Revision de Restricciones de Pagos (Relacionados)
             */
        doSeleccionarITEM(item) {
            /**
                   * Agrega documento de mismo auxiliar, codigo, referencia de periodo viejo para incluir a la lista de pagos
                   *
                   */

            // console.log('doSeleccionarITEM item :>> ', item.codigo + ' ' + item.auxiliar + ' ' + item.referencia + ' ' + item.periodo)
            const documentosViejos = this.storeDocumentos.filter(
                documento =>
                    documento.auxiliar === item.auxiliar &&
                    documento.codigo == item.codigo &&
                    documento.referencia == item.referencia &&
                    documento.periodo <= item.periodo
            )
            // console.log("documentosViejos :>> ", documentosViejos);
            if (documentosViejos.length > 0) {
                documentosViejos.forEach(docViejo => {
                    const estaRecibo = this.recibosSeleccionados.filter(doc => doc.id == docViejo.id)
                    if (estaRecibo.length == 0) {
                        this.recibosSeleccionados.push(docViejo)
                    }
                })
            }

            // Contador de Ciclo para verificar restricciones
            for (let iI = 0; iI < this.loopCount; iI++) {
                // console.log('Pasada iI :>> ', iI);
                this.recibosSeleccionados.forEach(recibo => {
                    const relacionados = this.storeRestricciones.filter(
                        restriccion =>
                            restriccion.auliliar === recibo.auxliar &&
                            restriccion.codigo === recibo.codigo)
                    // console.log('relacionados :>> ', relacionados);
                    if (relacionados.length > 0) {
                        relacionados.forEach(relacionado => {
                            // console.log('relacionado :>> ', relacionado);
                            const recibosRelacionado = this.storeDocumentos.filter(documento =>
                                documento.auxiliar === relacionado.rauxiliar &&
                                documento.codigo === relacionado.rcodigo &&
                                documento.referencia === recibo.referencia &&
                                documento.periodo <= recibo.periodo
                            )
                            // console.log('recibosRelacionado :>> ', recibosRelacionado);

                            recibosRelacionado.forEach(recRelacionado => {
                                const estaRecibo = this.recibosSeleccionados.filter(doc => doc.id == recRelacionado.id)
                                if (estaRecibo.length == 0) {
                                    this.recibosSeleccionados.push(recRelacionado)
                                }
                            })
                        })
                    }
                })
            }
        },
        /**
             * Eliminar documento de mismo auxiliar, codigo, referencia de periodo viejo para incluir a la lista de pagos
             *
             */
        doEliminarSeleccion(item) {
            // console.log('doEliminarSeleccion item :>> ', item.codigo + ' ' + item.auxiliar + ' ' + item.referencia + ' ' + item.periodo)
            const documentoNuevos = this.recibosSeleccionados.filter(
                documento =>
                    documento.auxiliar === item.auxiliar &&
                    documento.codigo == item.codigo &&
                    documento.referencia == item.referencia &&
                    documento.periodo >= item.periodo
            )
            // console.log("doEliminarSeleccion documentoNuevos :>> ", documentoNuevos);
            documentoNuevos.forEach(docNuevo => {
                // console.log('doEliminarSeleccion docNuevo :>> ', docNuevo.codigo + ' ' + docNuevo.auxiliar + ' ' + docNuevo.referencia + ' ' + docNuevo.periodo)
                this.recibosSeleccionados = this.recibosSeleccionados.filter(
                    doc =>
                        doc.id !== docNuevo.id
                )
                const relaciones = this.storeRestricciones.filter(
                    restriccion =>
                        restriccion.auxiliar === docNuevo.auxiliar &&
                        restriccion.codigo === docNuevo.codigo
                )
                // console.log('doEliminarSeleccion relaciones :>> ', relaciones)
                relaciones.forEach(relacionado => {
                    const reciboRelacionado = this.recibosSeleccionados.filter(
                        documento =>
                            documento.auxiliar === relacionado.rauxiliar &&
                            documento.codigo === relacionado.rcodigo &&
                            documento.referencia === docNuevo.referencia &&
                            documento.periodo >= docNuevo.periodo
                    )
                    //    console.log("doEliminarSeleccion reciboRelacionado :>> ", reciboRelacionado);
                    for (let iI = 0; iI < reciboRelacionado.length; iI++) {
                        this.recibosSeleccionados = this.recibosSeleccionados.filter(
                            doc => doc.id !== reciboRelacionado[iI].id
                        )
                        //    console.log("doEliminarSeleccion recibosSeleccionados :>> ", this.recibosSeleccionados);
                    }
                })
            })
        },
        async onReport() {
            window.print()
        },
        async initialize() {
            this.montoTotales = [0, 0, 0, 0]
            this.recibosSeleccionados = this.storeDocumentos
            this.paqueteSeleccionado = 'Todos'
            this.montoTotales = [0, 0, 0, 0]
            // this.timeOutCount = 0

            // if (!this.intervalHandle) {
            //     this.intervalHandle = setInterval(() => {
            //         this.timeOutCount = this.timeOutCount + 1
            //         console.log('this.timeOutCount  :>> ', this.timeOutCount, process.env.VUE_APP_PAGO_RECIBOS_TIME_OUT);
            //         if (this.timeOutCount > process.env.VUE_APP_PAGO_RECIBOS_TIME_OUT) {
            //             initialize()
            //             alert('Termino tiempo !!')
            //             this.onExit()
            //             // window.location = '/'
            //         }
            //     }, 1000);
            // }
        },
        async onCargarDocumentos() {
            await this.storeGetDocumentos({
                cedula: this.cedula,
                tipoConsulta: this.tipoConsulta
            })
        },
        paquetesDescripciones() {
            const descripciones = ['Todos']
            return descripciones.concat(this.storePaquetes.map(paquete => paquete.descripcion))
        },

        hayPagosQueHacer() {
            return this.montoTotales[3] > 0
        },
        /**
             * Revision Final de Pagos para ver si cumple con regla de negocio
             */
        revisionFinal() {
            this.mensajeRevision = []
            this.flsMensajeRevision = false

            this.recibosSeleccionados.forEach(recibo => {
                const documentosViejos = this.storeDocumentos.filter(documento =>
                    documento.auxiliar === recibo.auxiliar &&
                    documento.codigo == recibo.codigo &&
                    documento.referencia === recibo.referencia &&
                    documento.periodo < recibo.periodo
                )
                if (documentosViejos.length > 0) {
                    documentosViejos.forEach(docViejo => {
                        const estaRecibo = this.recibosSeleccionados.filter(doc => doc.id == docViejo.id)
                        if (estaRecibo.length == 0) {
                            this.mensajeRevision.push('Documento Anterior : ' + docViejo.referencia + ' ' + docViejo.periodo + ' ' + docViejo.descripcion)
                        }
                    })
                }
            })

            this.recibosSeleccionados.forEach(recibo => {
                const relacionados = this.storeRestricciones.filter(restriccion =>
                    restriccion.auliliar === recibo.auxliar &&
                    restriccion.codigo === recibo.codigo
                )
                relacionados.forEach(relacionado => {
                    const recibosRelacionado = this.storeDocumentos.filter(documento =>
                        documento.auxiliar === relacionado.rauxiliar &&
                        documento.codigo === relacionado.rcodigo &&
                        documento.referencia === recibo.referencia &&
                        documento.periodo === recibo.periodo
                    )
                    recibosRelacionado.forEach(recRelacionado => {
                        const estaRecibo = this.recibosSeleccionados.filter(doc => doc.id === recRelacionado.id)
                        if (estaRecibo.length == 0) {
                            this.mensajeRevision.push('Restriccion : ' + recRelacionado.referencia + ' ' + recRelacionado.periodo + ' ' + recRelacionado.descripcion)
                        }
                    })
                })
            })
            this.flsMensajeRevision = this.mensajeRevision.length > 0
        },
        onSeleccionarTodos() {
            this.recibosSeleccionados = this.storeDocumentos
        },
        onEliminarSeleccion() {
            this.recibosSeleccionados = []
            this.paqueteSeleccionado = ''
        },
        onSeleccionarPaquete() {
            console.log('this.paqueteSeleccionado :>> ', this.paqueteSeleccionado)
            this.recibosSeleccionados = []
            if (this.paqueteSeleccionado == 'Todos') {
                this.storeDocumentos.forEach(documento => {
                    const recibo = this.recibosSeleccionados.filter(recibo => recibo.id == documento.id)
                    if (recibo.length == 0) {
                        this.recibosSeleccionados.push(documento)
                    }
                    // } else {
                    //     this.recibosSeleccionados = this.recibosSeleccionados.filter(doc => doc.id !== recibo[0].id);
                    // }
                })
            } else {
                const comboPaquete = this.storePaquetes.filter(paquete => paquete.descripcion == this.paqueteSeleccionado)
                console.log('idPaquete comboPaquete :>> ', comboPaquete)

                if (comboPaquete.length > 0) {
                    this.storeDocumentos.forEach(documento => {
                        const paquete = comboPaquete[0].contenido.filter(combo =>
                            combo.auxiliar == documento.auxiliar &&
                            combo.codigo == documento.codigo
                        )
                        if (paquete.length > 0) {
                            const recibo = this.recibosSeleccionados.filter(recibo => recibo.id == documento.id)
                            if (recibo.length == 0) {
                                this.recibosSeleccionados.push(documento)
                            }
                            // } else {
                            //     this.recibosSeleccionados = this.recibosSeleccionados.filter(doc => doc.id !== recibo[0].id);
                            // }
                        }
                    })
                }
            }
        },
        /**
             *      Final de Pago de Recibos
             */
        async onPagarFactura() {
            this.flsDisableBTNPagarFactura = true
            this.flsBank = false
            const tipo = this.storeTipoConsulta
            if (this.montoTotales[3] > 0) {
                this.revisionFinal()
                if (this.mensajeRevision.length == 0) {
                    const recibosAPagar = {
                        cedula: this.cedula,
                        nombre: this.storeContribuyente.nombre,
                        service: 'ecommerce-vpos-orotina',
                        tipo: tipo.toUpperCase(),
                        cantidadDocumento: this.recibosSeleccionados.length,
                        monto: this.montoTotales[0],
                        interes: this.montoTotales[1],
                        iva: this.montoTotales[2],
                        total: this.montoTotales[3],
                        pagos: []
                    }
                    try {
                        this.recibosSeleccionados.forEach(recibo => {
                            recibosAPagar.pagos.push({
                                auxiliar: recibo.auxiliar,
                                codigo: recibo.codigo,
                                referencia: recibo.referencia,
                                ano: recibo.ano,
                                periodoExplicativo: recibo.mes,
                                periodo: recibo.periodo,
                                documento: recibo.documento,
                                descripcion: recibo.descripcion,
                                cuenta: recibo.cuenta,
                                monto: recibo.monto,
                                interes: recibo.interes,
                                iva: recibo.iva,
                                total: recibo.total
                            })
                        })

                        console.log('recibosAPagar :>> ', recibosAPagar)
                        const flsSendResult = await this.storeSendPagos(recibosAPagar)
                        this.flsBank = false
                        console.log('onPagarFactura  :>> ' + (flsSendResult ? 'OK' : 'Error Controlado'))

                        if (!flsSendResult) {
                            this.VPosMensajes = this.storeVPosMensaje
                            this.flsMensajePago = true
                        } else {
                            this.flsBank = true
                        }
                    } catch (error) {
                        alert('Error de Generacion de Pago')
                        console.log('erorr :>> ', {
                            ...error
                        })
                        this.flsBank = false
                    }
                    // window.location = '/'
                }
            } else {
                this.VPosMensajes = ['Por favor seleccione las líneas que desea pagar']
                this.flsMensajePago = true
            }
        },
        onExit() {
            window.location = '/'
            // this.initialize();
            // // clearInterval(this.intervalHandle)
            // this.$emit("modalResult");
        }
    }
}
</script>

<style scoped>
/deep/ tr.v-data-table__selected {
    background: #b3e5fc !important;
    color: black;
    /* #5fb870 !important; */
}

@media only screen and (min-width: 960px) {
    /deep/ .v-data-table__wrapper {
        height: 75vh !important;
    }
}

@media only screen and (min-width: 1280px) {
    /deep/ .v-data-table__wrapper {
        height: 80vh !important;
    }
}

/deep/ .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    font-size: max(0.7rem, 0.9vw);
    background: #eeeeee !important;
    font: bold;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: max(0.6rem, 0.8vw);
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
    padding: 0 8px;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
    padding: 0 8px;
}
</style>
